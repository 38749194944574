import { Container } from "../components/atoms/Container";
import { Layout } from "../layout/Layout";
import Typed from "react-typed";
import { InstallPWA } from "../components/organisms/PWA";

const IndexPage = () => {
  const talkAbout = [
    "React",
    "JavaScript",
    "TypeScript",
    "Redux",
    "Jamstack",
    "Next.js",
    "RESTful APIs",
    "HTML",
    "CSS",
    "PHP",
    "Python",
    "Swift",
    "Kotlin",
    "React-Native",
    "Tailwind",
    "GCP",
    "AWS",
  ];

  return (
    <Layout
      title="Krutik Parikh"
      description="A Software Engineer with a focus on Node.js, React.js and JavaScript. I have more than 5 years experience working in software engineering."
      keywords={[
        "Software Engineer",
        "Krutik Parikh",
        "Parikh Krutik",
        "Krutik",
        "parikrut",
        "Software Consulting",
        "Front-End",
        "Full-Stack",
        "React",
        "Node",
        "TypeScript",
        "JavaScript",
        "CSS",
        "Tailwind",
        "Back-End",
        "Full-Stack",
      ]}
    >
      <Container>
        <h1 className="headline mt-20 text-3xl md:text-5xl lg:text-6xl">
          Hey, I&apos;m Krutik Parikh 👋
        </h1>
        <p className=" my-8 text-lg">
          I am a passionate Software Engineer, who specialised in Full-Stack
          development using Node, React, and TypeScript/Javascript. As an
          advocate for web performance and accessibility and an evangelist for
          the Jamstack, I create amazing web applications to make the internet a
          better place. You can talk to me about{" "}
          <Typed
            loop
            typeSpeed={80}
            backSpeed={20}
            strings={talkAbout}
            smartBackspace
            backDelay={1000}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
          .
        </p>
        <p className=" my-8 text-sm font-bold">
          This portfolio is created with Next.js, TypeScript and Tailwind.
        </p>
        {/* <p className="">
          P.S. this website is open-source and available on{" "}
          <a
            href="https://github.com/parikrut/krutik-parikh"
            title="Link to Github repository"
            target="_blank"
            rel="noopener noreferrer"
            className="underlined font-bold relative border-b-2 border-grey-300 dark:border-grey-700 hover:border-b-0"
          >
            Github
          </a>
          .
        </p> */}
        <InstallPWA />
      </Container>
    </Layout>
  );
};

export default IndexPage;
