import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const router = useRouter();

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall]: any = useState(null);

  useEffect(() => {
    if (!router.isReady) return;
    const handler = (e: any) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, [router.isReady]);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <div className="flex flex-col justify-center">
      <div>
        <p className=" my-8 text-sm font-bold">
          You can also download the app on desktop (Magic of PWA).
        </p>
      </div>
      <div className="flex justify-center">
        <button
          className="block gradient-border transition transition-300 rounded-md px-4 py-2 headline hover:gradient-bg hover:text-off-white"
          id="setup_button"
          aria-label="Install app"
          title="Install app"
          onClick={onClick}
        >
          Download App
        </button>
      </div>
    </div>
  );
};

export { InstallPWA };
